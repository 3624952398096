export default {
    "common.Toolset": "Toolset",
    "common.Save": "Save",
    "common.Permissions": "Permissions",
    "login.required": "Please input your username!",
    "login.requiredpassword": "Please input your Password!",
    "login.title": "Welcome to the TPIA Portal",
    "login.forgot": "Forgot password ?",
    "login.sign": "SIGN IN",
    "login.access": "Access the Portal",
    "register.rules.emailrequired": "Please enter email address",
    "register.rules.email": "Email address Format Error!",
    "register.rules.confirmrequired": "Confirm Password",
    "register.button.up": "Sign Up",
    "register.button.in": "Sign In",
    "login.remember": "Remember me",
    "reset.lable.resetpasswrod": "Reset your password?",
    "reset.lable.reset": "Reset",
    "userlayout.foot.one": "By accessing the TPIA Portal you accept and agree to be bound to the",
    "userlayout.foot.two": "End-User License Agreement Terms and Conditions",
    "Blank.lable": "Blank",
    "userlayout.foot.Version": "Version:v",
    "userlayout.foot.Terms": "Terms of Use",
    "userlayout.foot.Privacy": "Privacy Policy",
    "userlayout.foot.three": "Cogeco Connexion™",
    "register.popover": "must have length greater than or equal to 6",
    "forgot.title": "Forgot your password?",
    "common.menu.admin": "Admin",
    "common.menu.workorder": "Work Order",
    "common.menu.systems": "System",
    "common.menu.customers": "Customer Search",
    "common.menu.customersadd": "Add Customer",
    "common.menu.filedchecklist": "Field Checks List",
    "common.menu.openworkorders": "Work Orders",
    "common.menu.reports.ordererror": "Fallout report",
    "common.menu.reports": "Reports",
    "common.menu.reports.city": "Customer by City",
    "common.menu.reports.gridreport": "Grid Report",
    "common.menu.reports.bulkimportreport": "Bulk Import Report",
    "common.menu.reports.status": "Customer Status",
    "common.menu.systems.portal": "Portal Maintenance",
    "common.menu.systems.users": "System Users",
    "common.menu.systems.roles": "System Roles",
    "common.menu.systems.carousel": "Manage Carousel",
    "common.menu.systems.logs": "System Logs",
    "common.menu.systems.email": "Manage Email",
    "common.menu.systems.setting": "System Setting",
    "common.menu.systems.language": "Manage Language",
    "common.menu.systems.notification": "System Notification",
    "common.menu.systems.configure": "Note Category",
    "common.menu.systems.notificationtype": "System Notification Type",
    "common.menu.systems.notificationtypeemail": "Notification Type Email",
    "common.menu.management": "Management",
    "common.menu.management.ISP": "TPIAs",
    "common.menu.management.PortalNotices": "Portal Notices",
    "common.menu.management.OrderTypes": "Order Types",
    "common.menu.management.Products": "Products",
    "common.menu.management.certifiedmodems": "Certified Modems",
    "common.menu.management.Communication": "Communication Log Categories",
    "common.menu.management.PortalUsers": "Portal Users",
    "common.menu.management.customerstatus": "Customer Status",
    "common.menu.management.orderstatus": "Order Status",
    "common.menu.management.bulkimport": "Bulk Import",
    "common.menu.reports.workorder": "Work Orders",
    "common.menu.reports.customerproduct": "Customers by Prod",
    "common.menu.reports.ordersummary": "Order Summary",
    "common.menu.reports.ordersummarybyday": "Order Summary by Day",
    "common.label.province": "Province",
    "common.province.ontario": "Ontario",
    "common.province.quebec": "Quebec",
    "common.label.city": "City",
    "common.label.count": "Count",
    "common.button.print": "Print",
    "common.button.export_to_csv": "Export to CSV",
    "common.label.product": "Product",
    "common.label.yes": "Yes",
    "common.label.no": "No",
    "common.label.remove": "Remove",
    "common.label.edit": "Edit",
    "common.label.delete": "Delete",
    "common.button.add": "Add",
    "common.button.update": "Update",
    "common.button.cancel": "Cancel",
    "common.button.submit": "Submit",
    "common.search.keyword": "Keywords",
    "common.search.text": "Search",
    "common.status.error": "Error",
    "common.button.search": "Search",
    "common.status.enabled": "Enabled",
    "common.status.Active": "Active",
    "common.status.disabled": "Disabled",
    "common.status.active": "Active",
    "common.button.save": "Save",
    "common.button.continue": "Continue",
    "common.button.confirm": "Confirm",
    "common.confirm_button.confirm_operation": "Confirm operation",
    "common.table.footer_text": "Records {a}-{b} of {c}",
    "common.input.placeholder": "Please input {a}",
    "common.select.placeholder": "Please Select {a}",
    "common.password.verification": "The passwords do not match!",
    "common.password.required": "Confirmed Password is required",
    "common.select.choose": "--Please Choose--",
    "common.table.selected": "Selected",
    "common.delete.text": "Are you sure you want to delete this item?",
    "common.select.text": "Please select",
    "common.button.ok": "Ok",
    "common.title.notification": "Notification",
    "common.text.welcome": "Welcome",
    "common.menu.profile": "Profile",
    "common.menu.timezone": "TimeZone",
    "common.menu.sign_out": "Sign Out",
    "common.columns.operating": "Operating",
    "common.columns.delete": "Delete",
    "common.columns.status": "Status",
    "common.columns.customertype": "Customer type",
    "common.columns.OrderID": "Order ID",
    "reports.label.end_customer_count": "End Customer Count",
    "reports.label.order_type": "Order Type",
    "reports.label.created": "Created",
    "reports.label.closed": "Closed",
    "reports.label.cancelled": "Cancelled",
    "reports.label.action_required": "Action Required",
    "reports.select.last30_days": "Last 30 Days",
    "reports.select.last7_days": "Last 7 Days",
    "reports.label.date": "Date",
    "reports.label.bookedondate": "Booked on Date",
    "reports.label.requested_date": "Requested Date",
    "reports.label.csg": "CSG",
    "reports.label.first_name": "First Name",
    "reports.label.last_name": "Last Name",
    "reports.label.address": "Address",
    "reports.label.date_from": "Date from",
    "reports.label.date_to": "Date to",
    "email_history.label.emailstatus": "Status",
    "reports.label.current_product": "Current Product",
    "reports.label.current_modem_type": "Current Modem Type",
    "reports.label.current_mac_address": "Current Mac Address",
    "reports.label.customer_Status": "Customer Status",
    "customer.status.active": "Active",
    "customer.status.disconnected": "Disconnected",
    "customer.status.field_check_required": "Field Check Required",
    "customer.status.prospect": "Prospect",
    "customer.status.suspended": "Suspended",
    "reports.label.request_type": "Request Type",
    "reports.label.order_num": "Order Num",
    "reports.label.created_date": "Created Date",
    "reports.label.scheduled_date": "Scheduled Date",
    "reports.label.closed_date": "Closed Date",
    "reports.label.cancelled_date": "Cancelled Date",
    "isp.label.csg": "CSG",
    "isp.label.isp_number": "ISP Number",
    "isp.label.csg.required": "Please input CSG",
    "isp.label.isp": "ISP",
    "isp.label.isp.required": "ISP Number",
    "isp.label.isp_name": "ISP Name",
    "isp.label.province": "Province",
    "isp.label.province.required": "Please input Province",
    "isp.label.account_number": "Account Number",
    "isp.label.account_number.required": "Please input Account Number",
    "isp.label.is_operational": "Is Operational",
    "isp.label.is_operational.required": "Please input Is Operational",
    "isp.label.ag_or_disag": "Ag or DisAg",
    "isp.label.disag": "DisAg",
    "isp.label.ag": "Ag",
    "isp.label.ag_or_disag.required": "Please Select Ag or DisAg",
    "portalnotice.label.note": "Note",
    "portalnotice.label.date_created": "Date Created",
    "portalnotice.label.created_by": "Created By",
    "portalnotice.label.expiry_date": "Expiry Date",
    "portalnotice.label.note.required": "Please input your Note",
    "portalnotice.label.expires": "Expires",
    "portalnotice.label.expires.required": "Please input your Expires",
    "portalnotice.modal.title": "Notification Details",
    "portalnotice.modal.details": "Details",
    "portalnotice.modal.days_remaining": "days remaining",
    "portalnotice.modal.expiry_date": "Expiry Date",
    "isp.modal.edit": "Edit Email Address",
    "isp.modal.add": "Add Email Address",
    "isp.label.email_address": "Email Address",
    "isp.label.email_address.required": "please enter a valid e-mail address",
    "certifiedmodems.label.vendor": "Vendor",
    "certifiedmodems.label.model": "Model",
    "certifiedmodems.label.firmware": "Firmware",
    "certifiedmodems.label.docsis_version": "DOCSIS version",
    "certifiedmodems.label.max_tier": "Max Tier",
    "certifiedmodems.label.province": "Province",
    "certifiedmodems.label.maxband": "Max Band",
    "certifiedmodems.label.product": "Product",
    "certifiedmodems.label.vendor.required": "Please input Vendor",
    "certifiedmodems.label.model.required": "Please input Model",
    "certifiedmodems.label.firmware.required": "Please input Firmware",
    "certifiedmodems.label.docsis_version.required": "Please input DOCSIS version",
    "certifiedmodems.label.max_tier.required": "Please input Max Tier",
    "certifiedmodems.label.maxband.required": "Please input Max Band",
    "certifiedmodems.label.province.required": "Please input Province",
    "communication.label.category_name": "Category Name",
    "communication.label.category_name.required": "Please input Category Name",
    "communication.label.description": "Description",
    "communication.label.description.required": "Please input Description",
    "products.label.product_name.existence.text": "The product name has already exist",
    "user.label.user_name.existence.text": "The user name has already exist",
    "user.label.email.existence.text": "The email has already exist",
    "products.label.product_name": "Product Name",
    "products.label.product_name.required": "Please Input your Product Name",
    "products.label.product_tye": "Product Type",
    "products.label.product_tye.disag": "DisAg",
    "products.label.product_tye.ag": "Ag",
    "products.label.product_type.required": "Please Select a Product Type",
    "products.label.modems": "Modems",
    "products.label.is_active": "Status",
    "products.label.is_active.required": "Please Select Status",
    "products.label.created": "Created",
    "products.label.modified": "Modified",
    "ordertype.label.order_type_name": "Order Type Name",
    "ordertype.label.order_type_name.required": "Please input Order Type Name",
    "ordertype.label.description": "Description",
    "ordertype.label.description.required": "Please input Description",
    "ordertype.label.order_buffer_days": "Order Buffer Days",
    "ordertype.label.order_buffer_days.required": "Please input Order Buffer Days",
    "ordertype.label.is_active_flag": "Is Active Flag",
    "ordertype.label.is_active_flag.required": "Please input Active Flag",
    "ordertype.label.order_type_code": "Order Type Code",
    "ordertype.label.required_items": "Required items",
    "ordertype.label.required_items.new_product": "New Product",
    "ordertype.label.required_items.modem_software": "Modem & Software",
    "ordertype.label.required_items.mac_address": "Mac address",
    "ordertype.label.required_items.schedule_information": "Schedule Information",
    "ordertype.label.required_items.scheduled_installation": "Scheduled Installation",
    "ordertype.label.required_items.customer_information": "Customer Information",
    "ordertype.label.required_items.customer_schedule": "Customer and Schedule",
    "ordertype.label.required_items.keeping_cogeco_service": "Keeping Cogeco Service",
    "ordertype.label.required_items.covid_safe": "Covid Safe",
    "ordertype.label.required_items.Order_Placed": "Order Placed",
    "ordertype.label.required_items.Notes_Category": "Notes Category",
    "orderstatus.label.orderstatustext": "Order Status Text",
    "orderstatus.label.orderstatustext.required": "Please input Order Status Text",
    "orderstatus.label.rpacode": "RPA Code",
    "orderstatus.label.rpacode.required": "Please select Active",
    "orderstatus.label.is_active": "Is Active",
    "orderstatus.label.is_active.required": "Please select Active",
    "orderstatus.label.description": "Description",
    "orderstatus.label.description.required": "Please input Description",
    "orderstatus.title": "View Order Status",
    "customerstatus.label.statustext": "Customer Status Text",
    "customerstatus.label.statustext.required": "Please input Customer Status Text",
    "customerstatus.title": "View Customer Status",
    "normal.success.orderstatusadd": "The order status has been added",
    "normal.success.orderstatusupdate": "The order status has been updated",
    "normal.success.orderstatusdelete": "The order type has been deleted",
    "systemsuser.label.name": "Name",
    "systemsuser.label.name.required": "Name is required",
    "systemsuser.label.isp.required": "ISP Name is required",
    "systemsuser.label.csg.required": "CSG Name is required",
    "systemsuser.label.user_name": "User Name",
    "systemsuser.label.user_name.required": "Username is required",
    "systemsuser.label.user_name.email": "Email address Format Error!",
    "systemsuser.label.role_groups": "Role Groups",
    "systemsuser.label.tpiauuid": "Tpiauuid",
    "systemsuser.label.isp_name": "ISP Name",
    "systemsuser.label.role_name": "Role Name",
    "systemsuser.label.create_date": "Create Date",
    "systemsuser.label.province": "Province",
    "systemsuser.label.province.required": "Province is required",
    "systemsuser.label.is_active": "Is Active",
    "user.type": "User Type",
    "systemsuser.button.rest_password": "Reset Password",
    "customer.label.csg": "CSG",
    "customer.label.scheduleddate": "Schedule Date",
    "customer.label.clientid": "Client ID",
    "customer.label.lastname": "Last Name",
    "customer.label.billingid": "Billing ID",
    "customer.label.firstname": "First Name",
    "customerdetail.editcustomer": "Edit Customer",
    "customerdetail.label.middlename": "Middle Name",
    "customer.label.address": "Address",
    "customer.label.new_address": "New Address (Relocate)",
    "customer.label.phonenumber": "Phone Number",
    "customerdetail.label.primaryphone": "Primary Number #",
    "customerdetail.label.secondaryphone": "Secondary Phone #",
    "customerdetail.label.language": "Language Preference",
    "customerdetail.label.csgdetail": "CSG name and number",
    "customer.label.package": "Package",
    "customer.label.macaddress": "Mac Address",
    "customer.label.mac": "Mac",
    "customerdetail.label.datestamp": "Date/Time",
    "customerdetail.label.username": "Entered By",
    "customerdetail.label.user": "User",
    "customerdetail.label.description": "Category",
    "customerdetail.label.comments": "Comments",
    "customerdetail.label.statusmessage": "Please input your Status",
    "customerdetail.label.macmessage": "Please input your MAC",
    "customerdetail.label.modem": "Modem",
    "customerdetail.label.product": "Product",
    "customerdetail.label.civicnumber": "Civic Number",
    "customerdetail.label.subcivicnumber": "Sub Civic Numbers",
    "customerdetail.label.unit": "Unit",
    "customerdetail.label.streetname": "Street Name",
    "customerdetail.label.province": "Province",
    "customerdetail.label.postalcode": "Postal Code",
    "customerdetail.label.services": "Does the customer have Cogeco Services ?",
    "customerdetail.label.site": "Is this a Commercial Site?",
    "customerdetail.label.notes": "Notes",
    "customerdetail.label.Category": "Note Category",
    "customerdetail.button.create": "Create Work Order",
    "customerdetail.button.view": "View Work Orders",
    "customerdetail.button.updatecustomer": "Update Customer Status",
    "customerdetail.button.update": "Update",
    "customerdetail.button.back": "Back",
    "customerdetail.button.note": "Submit Note",
    "customerdetail.title.entries": "Log Entries",
    "customerdetail.label.adressdetail": "Address Details",
    "customerdetail.modal.exact": "The “exact” customer address match exists",
    "customeradd.modal.confirmation": "Add New Customer Confirmation",
    "customeradd.modal.confirmationupdate": "Customer Status Update",
    "customerdetail.modal.verified": "The address entered could not be verified.  Do you wish to submit the address for a field check?",
    "customerdetail.modal.packgeid": "Package",
    "fieldchecklist.label.createdate": "Requested Date",
    "fieldchecklist.label.lastname": "Customer Name",
    "fieldchecklist.label.address": "Customer Address",
    "fieldchecklist.label.city": "City",
    "fieldchecklist.label.province": "Province",
    "fieldchecklist.label.postal": "Postal Code",
    "fieldchecklist.label.Datefrom": "Date from",
    "fieldchecklist.label.Dateto": "Date to",
    "fieldchecklist.label.packageName": "Product",
    "fieldchecklist.label.modem": "Modem Type",
    "fieldchecklist.label.macaddress": "Mac Address",
    "openworkorder.label.createdate": "Order Date",
    "openworkorder.label.ordertype": "Service Type",
    "openworkorder.label.lastname": "Last Name",
    "openworkorder.label.firstname": "First Name",
    "openworkorder.label.address": "Address",
    "openworkorder.label.id": "Work Order ID",
    "openworkorder.label.csgNumber": "CSG",
    "openworkorder.label.clientid": "Client ID",
    "openworkorder.label.datefrom": "Date from",
    "openworkorder.label.dateto": "Date to",
    "role.label.role_name": "Role Name",
    "role.label.role_name.required": "Please input Role Name",
    "role.label.role_type": "Role Type",
    "role.label.role_type.required": "Please select a role type！",
    "role.label.display_order": "Display Order",
    "role.label.display_order.required": "Please input Display Order",
    "role.label.is_active": "Is Active",
    "role.label.creation_date": "Creation Date",
    "role.label.action": "Action",
    "role.button.set_permission": "Set Permission",
    "systemslogs.label.action_type": "Action Type",
    "systemslogs.label.source": "Source",
    "systemslogs.label.user_name": "User Name",
    "systemslogs.label.platform": "Platform",
    "systemslogs.label.url": "Url",
    "systemslogs.label.url_referrer": "Url Referrer",
    "systemslogs.label.ip_address": "Ip Address",
    "systemslogs.label.description": "Description",
    "systemslogs.label.operation_time": "Operation Time",
    "systemslogs.label.method": "Method",
    "systemslogs.label.created": "Created",
    "systemslogs.label.request_type": "Request Type",
    "systemslogs.label.ip": "Ip",
    "systemslogs.label.request_time": "Request Time",
    "systemslogs.label.response_code": "Response Code",
    "systemslogs.label.response_data": "Response Data",
    "systemslogs.label.response_time": "Response Time",
    "systemslogs.label.request_data": "Request Data",
    "workorderdetail.label.name": "Name",
    "workorderdetail.label.sales_order_number": "Sales Order Number",
    "workorderdetail.label.adress": "Address",
    "workorderdetail.label.postal": " City/Prov/Postal Code",
    "workorderdetail.label.primaryphone": "Primary Phone #",
    "workorderdetail.label.Current": "Current Configuration",
    "workorderdetail.label.book": "Booking Details",
    "workorderdetail.label.bookon": "Booked on",
    "workorderdetail.label.ordertype": "Work Order Type",
    "workorderdetail.label.ms": "Modem&Software",
    "workorderdetail.label.ms.required": "Please Select your Modem&Software",
    "workorder.label.product.required": "Please Select your Product",
    "workorder.label.notes.required": "Please input your Notes",
    "workorderdetail.label.updatestatus": "Update Status",
    "workorderdetail.label.updatecontent": "Update Content",
    "workorderdetail.label.reschedule": "Reschedule",
    "workorderdetail.label.check": "Check Status",
    "workorderdetail.label.tpiapackage": "TPIA Internet Package",
    "workorderdetail.label.scheduled": "Scheduled",
    "workorderdetail.label.user": "User",
    "workorder.label.placed.required": "Please choose Order has been placed with current TPIA",
    "workorder.label.placed": "Order has been placed with current TPIA",
    "workorderdetail.message.scheduled": "The scheduled date is not valid.",
    "workorder.label.allday": "All day",
    "workorder.label.morning": "Morning",
    "workorder.label.afternoon": "Afternoon",
    "workorder.label.evening": "Evening",
    "workorder.label.covid_safe": "Covid Safe",
    "note_category.label.description": "Description",
    "note_category.label.taid": "TAID",
    "note_category.label.pid": "PID",
    "note_category.label.is_active": "Is Active",
    "note_category.label.is_deleted": "Is Deleted",
    "note_category.label.created": "Created",
    "note_category.label.modified": "Modified",
    "notification.label.created_by": "Created By",
    "notification.label.created": "Created",
    "notification.label.date_from": "Date from",
    "notification.label.date_to": "Date to",
    "notification.label.notification_details": "Notification Details",
    "notification.label.date_created": "Date Created",
    "notification.label.expiry_date": "Expiry Date",
    "notification.label.maintenance": "Maintenance",
    "notification.label.type": "Type",
    "notification.label.type_name": "Type Name",
    "notification.label.trigger": "Trigger",
    "notification.label.message": "Message",
    "notification.label.target": "Target",
    "notification.label.description": "Description",
    "notification.label.email_template": "Email Template",
    "notification.label.email": "Email",
    "notification.label.users": "Users",
    "notification.label.create_time": "Create Time",
    "portal.label.text1": "Is the portal down for Maintenance",
    "portal.label.text2": "Display from the time period",
    "portal.label.maintenance_time": "Maintenance Time",
    "portal.label.maintenance_content": "Maintenance Content",
    "workorder.label.ordertype": "Order Type",
    "workorder.message.ordertype": "Please select an order type",
    "workorder.label.newproduct": "New Product",
    "workorder.label.newmac": "MAC Address",
    "workorder.message.newmac": "The input characters to the numbers 0-9, and letters A-F, and must be 12 characters.",
    "workorder.label.newmac.required": "Please input your MAC Address",
    "dictionary.label.language_code": "Language Code",
    "dictionary.label.category": "Category",
    "dictionary.label.code": "Code",
    "dictionary.label.type": "Type",
    "dictionary.label.display_name": "Display Name",
    "dictionary.label.key_value": "Key Value",
    "dictionary.label.display_order": "Display Order",
    "dictionary.label.description": "Description",
    "setting.label.key": "Key",
    "setting.label.value": "Value",
    "email_history.label.sender": "Sender",
    "email_history.label.to": "To",
    "email_history.label.subject": "Subject",
    "email_history.label.body": "Body",
    "email_history.label.send_time": "Send Time",
    "email_template.label.email_template_code": "Email Template Code",
    "email_template.label.priority": "Priority",
    "email_template.label.body_encoding": "Body Encoding",
    "email_template.label.body_encoding.required": "Please input Body Encoding",
    "email_template.label.subject": "Subject",
    "email_template.label.body_content": "Body Content",
    "email_template.label.ics_body": "Ics Body",
    "email_template.label.description": "Description",
    "language.label.resource_label": "Label",
    "language.label.resource_label.required": "Please input Label",
    "language.label.resource_page": "Page",
    "language.label.resource_page.required": "Please input Page",
    "language.label.resource_text": "Text",
    "language.label.english_text": "English Text",
    "language.label.english_text.required": "Please input English Text",
    "language.label.french_text": "French Text",
    "language.label.french_text.required": "Please input French Text",
    "language.label.german_text": "German Text",
    "language.label.german_text.required": "Please input German Text",
    "language.label.spanish_text": "Spanish Text",
    "language.label.spanish_text.required": "Please input Spanish Text",
    "language.label.italian_text": "Italian Text",
    "language.label.italian_text.required": "Please input Italian Text",
    "language.label.netherlands_text": "Netherlands Text",
    "language.label.netherlands_text.required": "Please input Netherlands Text",
    "workorderdetail.label.mac.required": "The input characters to the numbers 0-9, and letters A-F, and must be 12 characters.",
    "workorderdetail.label.mac.verification": "The Mac Address Entered is Already in Use with Another Customer.",
    "workorderdetail.button.view": "View Complete Customer Details",
    "workorderdetail.text.current_configuration": "Current Configuration",
    "workorderdetail.label.status": "Status",
    "workorderdetail.label.mac": "MAC",
    "workorderdetail.label.modem": "Modem",
    "workorderdetail.label.product": "Product",
    "workorderdetail.text.booking_details": "Booking Details",
    "workorderdetail.label.modem_software": "Modem&Software",
    "workorderdetail.label.booked_on": "Booked on",
    "workorderdetail.label.work_order_type": "Work Order Type",
    "workorderdetail.button.update_status": "Update Status",
    "workorderdetail.button.update_content": "Update Content",
    "workorderdetail.button.reschedule": "Reschedule",
    "workorderdetail.button.check_status": "Check Status",
    "workorderdetail.label.notes": "Notes",
    "workorderdetail.label.notes.required": "Please input your Notes",
    "workorderdetail.label.note_category": "Note Category",
    "workorderdetail.label.note_category.required": "Please Select your Category",
    "workorderdetail.button.submit_note": "Submit Note",
    "workorderdetail.label.log_entries": "Log Entries",
    "workorderdetail.label.ptia_internet_package": "TPIA Internet Package",
    "workorderdetail.title.update_reschedule": "Updates Reschedule",
    "workorderdetail.label.scheduled.verification": "The scheduled date is not valid.",
    "profile.label.old_password": "Old Password",
    "profile.label.old_password.required": "Old Password is required",
    "profile.label.password.length": "must have length greater than or equal to 10",
    "profile.label.new_password": "New Password",
    "profile.label.new_password.required": "New Password is required",
    "profile.label.username": "Username",
    "profile.label.username.required": "Username is required",
    "profile.label.name": "Name",
    "profile.label.name.required": "Name is required",
    "profile.label.phone.required": "Phone is required",
    "profile.label.phone": "Phone",
    "profile.label.preferred_language": "Preferred Language",
    "profile.label.language.english": "English",
    "profile.label.language.french": "French",
    "profile.label.language.german": "German",
    "profile.label.language.spanish": "Spanish",
    "profile.label.language.italian": "Italian",
    "profile.label.language.netherlands": "Netherlands",
    "timezone.label.timezone": "Timezone",
    "timezone.label.date_format": "Date Format",
    "timezone.label.time_format": "Time Format",
    "customerdetail.tpia.error_info": "No service available",
    "customer.create_workorder.text": "Existing work order processing, new work order cannot be submitted at this time.",
    "customerdetail.address.check": "The exact customer address match exists",
    "settings.tabs.settings": "Settings",
    "settings.tabs.dictionary": "Dictionary",
    "note_category.tabs.note_category": "Note Category",
    "email.tabs.email_history": "Email History",
    "email.tabs.email_template": "Email Template",
    "logs.tab.call_in_log": "Call In Log",
    "logs.tab.call_out_log": "Call Out Log",
    "logs.tab.operation_log": "Operation Log",
    "customerdetail.label.actionid": "Action",
    "customer.label.firstname.required": "Please input your First Name",
    "customer.label.middlename": "Middle Name",
    "customer.label.lastname.required": "Please input your Last Name",
    "customer.label.primaryphone": "Primary Number #",
    "customer.label.primaryphone.required": "Please input your Primary Phone",
    "customer.label.primaryphone.format": "Please enter the correct phone",
    "customer.label.secondaryphone": "Secondary Phone #",
    "customer.label.address_details": "Address Details",
    "customer.label.civicnumber": "Civic Number",
    "customer.label.civicnumber.required": "Please input your Civic Number",
    "customer.label.csg.required": "Please Select a CSG",
    "customer.label.subcivicnumber": "Sub Civic Numbers",
    "customer.label.unit": "Unit",
    "customer.label.streetname": "Street Name",
    "customer.label.streetname.required": "Please input your Street Name",
    "customer.label.city": "City",
    "customer.label.city.required": "Please input your City",
    "customer.label.province": "Province",
    "customer.label.province.required": "Please Select a Province",
    "customer.label.postalcode": "Postal Code",
    "customer.label.postalcode.required": "Please input a Postal Code",
    "customer.label.postalcode.format": "Please enter the correct postal code",
    "customer.button.Check_services": "Check for Service",
    "customer.label.services": "Does the customer have Cogeco Services ?",
    "customer.label.services.required": "Please choose whether to keep the current Cogeco service",
    "customer.label.site": "Is this a Commercial Site?",
    "customer.label.site.required": "Please choose whether it is a commercial website or not",
    "customer.label.notes": "Notes",
    "customer.label.Category": "Note Category",
    "customeradd.modal.confirmation.text1": "Your New Customer submission was Successfully Processed",
    "customeradd.modal.confirmation.text2": "An email will be sent Confirming the details of this new Customer",
    "customeradd.modal.confirmation.text3": "You will now be able to interact with this Customer in the TPIA Portal",
    "customeradd.modal.confirmationupdate.text1": "Your Request for Field Check has been Successfully Processed",
    "customeradd.modal.confirmationupdate.text2": "An email will be sent Confirming the Status change of this customer",
    "customer.label.streetname.select": "Please select Street Name",
    "common.button.start_over": "Start Over",
    "customer.label.language": "Language Preference",
    "customer.label.language.format": "Please select the Language Preference",
    "customer.label.csgdetail": "CSG name and number",
    "customer.label.statusmessage": "Please input your Status",
    "customer.label.macmessage": "Please input your MAC",
    "customer.label.modem": "Modem",
    "customer.label.product": "Product",
    "common.button.back": "Back",
    "workordertetail.label.data": "Date",
    "customer.label.ordertype": "Service Type",
    "customer.label.id": "Work Order ID",
    "customer.label.datefrom": "Order Date from",
    "customer.label.dateto": "Order Date to",
    "customer.label.schedule_datefrom": "Schedule Date from",
    "customer.label.schedule_dateto": "Schedule Date to",
    "orkorderdetail.label.adress": "Address",
    "workorderdetail.label.csgname_number": "CSG name and number",
    "customer.label.createdate": "Order Date",
    "customer.label.csgNumber": "CSG",
    "fieldcheck.label.modem": "Modem Type",
    "fieldcheck.label.packageName": "Product",
    "fieldcheck.label.dateto": "Date to",
    "fieldcheck.label.datefrom": "Date from",
    "fieldcheck.label.postal": "Postal Code",
    "fieldcheck.label.address": "Customer Address",
    "fieldcheck.label.lastname": "Customer Name",
    "fieldcheck.label.createdate": "Requested Date",
    "workorder.label.kepping_service": "Is customer keeping Cogeco Services?",
    "workorder.modal.title.scheduling": "Work Order Scheduling",
    "workorder.label.time_block.required": "Please select a time block.",
    "workorder.label.time_block": "Time Block",
    "workorder.label.scheduled_date.valid": "The scheduled date is not valid.",
    "workorder.label.not_selected": "Not selected",
    "workorder.label.scheduled_date": "Scheduled Date",
    "workorder.modal.text": "NOTE – Schedule dates are only available starting {a} days from today – {b}",
    "workorder.modal.text1": "Your Work Order has been received.  Once the order has been validated, it will be process and scheduled.",
    "workorder.modal.text2": "An email will be sent Confirming the details of the Work Order.",
    "workorder.modal.text3": "Thank You for your Order!",
    "workorder.modal.title.text2": "Work Order Confirmation",
    "workorder.label.package": "Package",
    "workorder.label.package.required": "Please select an Package",
    "workorder.label.primaryphone.required": "Please input your Primary Phone",
    "workorder.label.civicnumber.required": "Please input your Civic Number",
    "workorder.label.streetname.required": "Please input your Street Name",
    "workorder.label.city.required": "Please input your City",
    "workorder.label.province.required": "Please Select your Province",
    "workorder.label.postal_code": "Postal Code",
    "workorder.label.postal_code.required": "Please input your Postal Code",
    "workorder.scheduled.message": "Please select a scheduled date.",
    "workorder.label.site": "Is this a Commercial Site?",
    "workorder.label.website.required": "Please choose whether it is a commercial website or not",
    "workorder.label.keeping_service.required": "Please choose whether to keep the current Cogeco service",
    "workorder.label.keeping_service": "Is the Customer keeping current Cogeco services?",
    "workorder.label.lastname.required": "Please input your Last Name",
    "workorder.label.firstname.required": "Please input your First Name",
    "user.title.Add": "Add",
    "user.label.csgname": "CSG Name",
    "user.message.csgname": "Please Select your CSG Name",
    "reports.customer_by_city.title": "Customer by City",
    "reports.customer_count_by_product.title": "View Customer Count By Product",
    "reports.order_summary.title": "Open Order Summary",
    "reports.order_summary_by_day.title": "Open Order Summary by Day",
    "reports.customer_status.title": "Customer Status",
    "reports.open_work_orders.title": "Open Work Orders",
    "systemsisp.title": "View TPIAs",
    "systemsisp.details_title": "TPIA Details",
    "portalnotice.title": "View Portal Notices",
    "ordertype.title": "View Order Types",
    "ordertype.details.title": "Order Type Details",
    "products.title": "View Products",
    "certifiedmodems.title": "View Certified Modems",
    "certifiedmodems.details.title": "Certified Modems Details",
    "bulkimport.title": "Bulk Import",
    "bulkimport.details.title": "Bulk Import Details",
    "communication.details.title": "Communication Log Details",
    "communication.title": "View Communication Log Categories",
    "notification.type_email.title": "Notification Types Email",
    "notification.type.title": "Notification Types",
    "notification.title": "Notification",
    "portal.title": "Portal",
    "email.title": "Email / {a}",
    "logs.title": "Logs / {a}",
    "systemsroles.title": "Roles",
    "settings.title": "Settings / {a}",
    "systemsuser.title": "Users",
    "workorderdetail.lable.date": "Date",
    "workorderdetail.lable.category": "Category",
    "workorderdetail.lable.entered_by": "Entered By",
    "workorderdetail.lable.action": "Action",
    "workorderdetail.lable.comments": "Comments",
    "workorderdetail.page_title": "View Work Order Details",
    "workorderdetail.title": "View Work Order Details -  Order #{a} - Client ID {b} {c}",
    "workorderdetail.title.billingid": "- Billing ID {d}",
    "fieldcheck.title": "View Field Checks",
    "view_workorder.title": "View Work Orders-Client ID {a} {b}",
    "view_workorder.title.billingid": "- Billing ID {c}",
    "create_workorder.title": "Create Work Orders-Client ID {a} {b}",
    "create_workorder.title.billingid": "- Billing ID {c}",
    "add_customer.title": "Add New Customer",
    "customer.title": "Search Customer",
    "customerdetail.title": "Customer Detail-Client ID {a} {b}",
    "customerdetail.title.billingid": "- Billing ID {c}",
    "systemsisp.notice.add_text": "The e-mail record has been added",
    "systemsisp.notice.update_text": "The e-mail record has been updated",
    "systemsisp.columns.email": "Email Addresses for Notification",
    "common.success.delete_text": "This item has been deleted",
    "portal.success.add_text": "The maintenance window has been added",
    "openworkorder.title": "Search Work Orders",
    "common.button.close": "Close",
    "customerdetail.page_title": "View Customer Detail",
    "normal.success.checkstatus": "The Work Order has not yet been updated, please try again in a few minutes",
    "normal.success.savenotes": "Your note has been successfuly added",
    "normal.success.saveworkorderstatus": "The work order status has been succesfully updated",
    "normal.success.saveworkorderschedule": "The work order schedule has been succesfully updated",
    "normal.success.saveworkordercontent": "The work order content has been succesfully updated",
    "normal.success.workordersave": "The work order has been succesfully added",
    "normal.success.certifiedupdate": "The Modem has been updated",
    "normal.success.modemadd": "Your Modem has been successfully added",
    "normal.success.categoryadd": "The note category has been added",
    "normal.success.categoryupdate": "The note category has been update",
    "normal.success.customerstatus": "The customer status has been succesfully updated",
    "normal.success.noteadd": "Your note has been successfuly added",
    "normal.success.savetpia": "The TPIA record has been created",
    "normal.success.notecategoryupdate": "The note category ha s been updated",
    "normal.success.notecategoryadd": "The note category ha s been added",
    "normal.success.portal_noticeadd": "Your portal notice has been added",
    "normal.success.productupdate": "The product has been updated",
    "normal.success.productadd": "The product has been added",
    "normal.success.roleupdate": "The role has been update",
    "normal.success.roleadd": "The role has been added",
    "normal.success.permissions": "The permissions has been updated",
    "normal.success.tpiaupdate": "The TPIA record has been updated",
    "normal.success.userupdate": "The user has been updated",
    "normal.success.useradd": "The user has been added",
    "normal.success.ordertypeadd": "The order type has been added",
    "normal.success.ordertypeupdate": "The order type has been updated",
    "normal.success.resetpassword": "An email has been sent to the user to reset their password",
    "normal.success.unlock": "The user has been unlocked",
    "profile.tabs.profile": "Profile",
    "profile.tabs.password": "Password",
    "profile.title": "Profile / {a}",
    "profile.title.change_profile": "Change Profile",
    "profile.title.change_password": "Change Password",
    "login.forgot_password": "Forgot password ?",
    "common.button.reset": "Reset",
    "reset_password.password.strength_strong": "Strength:strong",
    "reset_password.password.strength_medium": "Strength:Medium",
    "reset_password.password.strength_week": "Strength:Week",
    "reset_password.password.error": "The passwords do not match!",
    "reset_password.password.required": "Please input your Password!",
    "reset_password.text": "Reset your password?",
    "reset_password.password.info": "must have length greater than or equal to 6",
    "reset_password.password": "Password",
    "reset_password.confirm_password": "Confirm Password",
    "reset_password.title": "Reset Password",
    "reset_password.subtitle": "Please enter the new password. ",
    "notecategory.message": "Please select your Note Category",
    "note.message": "Please input your Note",
    "workorder.mac.modal": "The Mac Address Entered is Already in Use with Another Customer.",
    "workorder.covidsafe.modal": "The work order cannot be created unless residence is Covid safe",
    "workorder.time.modal": "It is not  allowed to have more than one work order open at a time.",
    "reports.label.province": "Province",
    "reports.label.city": "City",
    "reports.label.postal_code": "Postal Code",
    "customer.label.compassid": "Billing ID",
    "customeradd.modal.modify_confirmation": "Modify Customer Confirmation",
    "customeradd.modal.modify_confirmation.text1": "Your Customer submission was Successfully Processed",
    "customeradd.modal.modify_confirmation.text3": "You will now be able to interact with this Customer in the TPIA Portal",
    "reports.ordererror.title": "Fallout Report",
    "ordertype.label.rpa_code": "Rpa Code",
    "ordertype.label.rpa_code.required": "Please select Rpa Code",
    "certifiedmodems.label.product.required": "Please select Product",
    "normal.success.dictionary_update": "The dictionary has been updated",
    "normal.success.dictionary_add": "Your dictionary has been successfully added",
    "normal.success.settings_update": "The Key has been updated",
    "normal.success.settings_add": "Your Key has been successfully added",
    "normal.success.customer_status_update": "The status has been updated",
    "normal.success.customer_status_add": "The status has been added",
    "normal.success.notification_update": "The email has been updated",
    "normal.success.notification_add": "Your email has been successfully added",
    "normal.success.notification_type_update": "The type has been updated",
    "normal.success.notification_type_add": "Your type has been successfully added",
    "normal.success.email_template_update": "The Email Template has been updated",
    "normal.success.email_template_added": "The Email Template has been added",
    "report.title.gridreport": "Grid Report",
    "report.title.bulkimportreport": "Bulk Import Report",
    "report.title.removerows": "Remove Rows",
    "report.title.SortOrder": "Sort Order",
    "page.termofuse": "Terms of Use",
};